import React, {useEffect, useState} from 'react';
import "../../styles/components/_marketTable.scss";
import {Area, AreaChart, Tooltip, XAxis, YAxis} from "recharts";
import BuySellPopup from "./BuySellPopup";
import OrderPreviewPopup from "./orderPreview";
import ConfirmPopup from "./confirmPopup";
import SuccessPopup from "./successPopup";
import {numberWithCommas, numberWithCommasAndDecimal} from "../../utils/helper";
import {sortByField} from "../../utils/gridUtils";
import {fetchPerformanceChart} from "../../services/portfolioService";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import {useRouter} from "next/router";

interface ButtonProps {
    text: any,
    btn_class: any,
    setPopupProps: any,
    row: any,
}

const Button = ({btn_class, text, setPopupProps, row}: ButtonProps) => {
    const openPopup = (type: string, securityUUID: string) => {
        setPopupProps(type, securityUUID);
    }
    return (
        <button className={btn_class} onClick={() => openPopup(text, row)}>{text}</button>
    )
}

interface TableProps {
    columns: any,
    data: any,
    theme: any,
    AllData?: any,
    category?: number,
    isChart: boolean,
    getChildRows?: any,
    selectedPortfolio?: any,
    selectedDate: any,
    currencySymbol: any,
    idField?: string,
    noDataText?: string,
}

const MarketTable: React.FC<TableProps> = (props) => {
    const router = useRouter()
    const [open, setOpen] = React.useState(false);
    const [reviewPopup, setReviewPopup] = React.useState(false);
    const [activeSortSection, setActiveSortSection] = useState("");
    const [direction, setDirection] = useState<number>(0);
    const [confirmPopup, setConfirmPopup] = React.useState(false);
    const [openRows, setOpenRows] = useState<any>({});
    const [successPopup, setSuccessPopup] = React.useState(false);
    const [clearFilterValue, setClearFilterValue] = React.useState(false);
    const [sparkLineCharts, setSparkLineCharts] = useState<any>([]);
    const [popupType, setPopupType] = React.useState("");
    const [row, setRow] = React.useState("");
    const [portfolio, setPortfolio] = React.useState("");
    const [portfolioName, setPortfolioName] = React.useState("");
    const [productPrice, setProductPrice] = React.useState("");
    let clientId: any = sessionStorage.getItem("clientId");
    const [payload, setPayload] = React.useState({});

    useEffect(() => {
        if (props.isChart) {
            let securityIdArr: any = [];
            let finalData: any = [];
            props?.data?.map((obj: any) => {
                if (obj?.securityUUID) {
                    securityIdArr.push(obj?.securityUUID)
                }
            })

            const body = {
                securityIdList: securityIdArr,
            }

            if (props?.selectedDate && body) {
                fetchPerformanceChart(body, moment(props?.selectedDate).subtract(4, 'd').format('DD-MM-YYYY'), moment(props?.selectedDate).format("DD-MM-YYYY"))
                    .then(res => {
                        securityIdArr?.length > 0 && securityIdArr?.map((id: any) => {
                            finalData?.push(res?.benchmark?.[id])
                        });
                        setSparkLineCharts(finalData);
                    }).catch((e) => {
                    console.log(e);
                });
            }
        }
    }, [props?.data, props.isChart, direction]);

    const handleSectionFilter = (heading: string) => {
        if (activeSortSection === heading) {
            setActiveSortSection(heading);
            if (direction === 1) {
                setDirection(-1);
            } else {
                setDirection(1);
            }
        } else {
            setActiveSortSection(heading);
            setDirection(1);
        }
    }

    const renderSparklineWithTooltip = (name: any, data: any) => {
        let dataArr: any = [];
        data?.map((d: any) => {
            if (d?.unitPrice) {
                dataArr?.push({
                    name: "5D Change",
                    value: parseFloat(d?.unitPrice),
                    change: parseFloat(d?.changeInValue)
                })
            }
        })

        let changeFlag: any;

        if (dataArr?.length > 0) {
            changeFlag = dataArr[dataArr?.length - 1]?.change > 0 ? 1 : 0;
        }

        return (
            <> {dataArr?.length > 0 ? <AreaChart
                width={150}
                height={40}
                data={dataArr}
            >
                {changeFlag ? (
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#4cbb17" stopOpacity={0.3}/>
                            <stop offset="95%" stopColor="#4cbb17" stopOpacity={0.1}/>
                        </linearGradient>
                    </defs>
                ) : (
                    <defs>
                        <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="red" stopOpacity={0.3}/>
                            <stop offset="95%" stopColor="red" stopOpacity={0.1}/>
                        </linearGradient>
                    </defs>
                )}
                <XAxis dataKey="name" hide={true}/>
                <YAxis hide={true} domain={[Math.min.apply(Math, dataArr.map(function (o: any) {
                    return o.value
                })), Math.max.apply(Math, dataArr.map(function (o: any) {
                    return o.value
                }))]}/>
                <Tooltip
                    labelStyle={{color: "#00003e"}}
                    itemStyle={{color: "#55557e"}}
                    formatter={function (value: any, name: any) {
                        return `${parseFloat(value)?.toFixed(4)}`;
                    }}
                    labelFormatter={function (value) {
                        return `${value}`;
                    }}/>
                <Area dot={true} type="monotone" dataKey="value" opacity={0.8}
                      stroke={`${changeFlag ? "#4cbb17" : "red"}`} fillOpacity={1}
                      fill={`${changeFlag ? "url(#colorUv)" : "url(#colorUv2)"}`}/>
            </AreaChart> : "━━━"}</>
        );
    }

    const handleSubmit = (portfolio: string, portfolioName: string, payload: any, productLatestPrice: any) => {
        setPayload(payload);
        setPortfolio(portfolio);
        setPortfolioName(portfolioName);
        setProductPrice(productLatestPrice);
        setOpen(false);
        setReviewPopup(true);
    };

    const handleSubmit2 = () => {
        setReviewPopup(false);
        setConfirmPopup(true);
    };

    const handleSubmit3 = () => {
        setConfirmPopup(false);
        setSuccessPopup(true);
    };

    const handlePreviewEdit = () => {
        setReviewPopup(false);
        setOpen(true);
    };

    const handleClose2 = () => {
        setReviewPopup(false);
    };

    const handleClose3 = () => {
        setConfirmPopup(false);
    };

    const handleClose4 = () => {
        setSuccessPopup(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openPopUp = (type: string, row: string) => {
        setOpen(true);
        setPopupType(type);
        setRow(row);
    }

    const handlePopupType = (type: string) => {
        setPopupType(type);
    }

    const formatDataByType = (data: any, type: string, symbol: string) => {
        let tableCurrencySymbol: string = symbol;
        if (type === "text") {
            return data;
        } else if (type === "quantity") {
            return numberWithCommas(data);
        } else if (type === "price" || type === "gain/loss") {
            return tableCurrencySymbol + " " + data
        } else if (type === "priceWithoutColor") {
            return tableCurrencySymbol + " " + data
        } else if (type === "percentage") {
            return numberWithCommasAndDecimal(data) + "%"
        } else if (type === "4DP") {
            return data
        } else if (type === "4DPPrice") {
            return tableCurrencySymbol + " " + numberWithCommasAndDecimal(data, 4)
        } else if (type === "2DPPrice") {
            return tableCurrencySymbol + " " + numberWithCommasAndDecimal(data, 2)
        }
    }

    const formatDataForColor = (data: any, type: string) => {
        if (type === "price" || type === "gain/loss") {
            return data ? data > 0 ? "increase" : "decrease" : "";
        }
    }

    const openWalletPage = (type: string) => {
        router.push(`wallet?type=${type}`)
    }

    return (
        <>
            <div className="markettable-wrapper">
                <div className="markettable">
                    <div className="markettable__columns">
                        {
                            props?.columns?.map((col: any, i: number) => {
                                return (
                                    <div
                                        onClick={() => {
                                            col?.sortable ? handleSectionFilter(col.key) : undefined
                                        }}
                                        className={`${col?.align === "left" ? "left" : "right"} ${col?.sortable && "cursor"} ${col.medium ? "markettable__columns-medium" : col.large ? "markettable__columns-large" : "markettable__columns-small"}`}
                                        key={i}>
                                        <div>
                                            {col?.title}
                                        </div>
                                        {col?.sortable &&
                                            <i className={`${direction === 1 ? "bi bi-chevron-compact-up down" : "bi bi-chevron-compact-down down"}`}/>}
                                    </div>
                                )
                            })
                        }
                    </div>

                    {sortByField(props?.data, activeSortSection, direction)?.map((d: any, index: number) => {
                        return (
                            <>
                                <div className="markettable__values" key={index}>
                                    {
                                        props?.columns?.map((col: any, i: number) => {
                                            return (
                                                <>
                                                    <div
                                                        className={`${col?.align === "left" ? "left" : "right"} ${col.medium ? "markettable__values-medium" : col.large ? "markettable__values-large" : "markettable__values-small"}`}
                                                        key={i}>
                                                        {col.type === "buttons" && ["BOND", "CIS", "EQUITY", "ETF", "BILL", "CASH"]?.includes(d?.assetClass) ? (
                                                            <div>
                                                                <Button btn_class="buy" text="BUY"
                                                                        setPopupProps={openPopUp}
                                                                        row={d}
                                                                />
                                                                <Button btn_class="sell" text="SELL"
                                                                        setPopupProps={openPopUp}
                                                                        row={d}
                                                                />
                                                            </div>
                                                        ) : col.type === "live_buttons" && ["BOND", "CIS", "EQUITY", "ETF", "BILL", "CASH"]?.includes(d?.assetClass) ? (
                                                            <div>
                                                                {d["orderSide"] === "BUY" && (
                                                                    <Button btn_class="buy" text="BUY"
                                                                            setPopupProps={openPopUp}
                                                                            row={d}
                                                                    />
                                                                )}
                                                                {d["orderSide"] === "SELL" && (
                                                                    <Button btn_class="sell" text="SELL"
                                                                            setPopupProps={openPopUp}
                                                                            row={d}
                                                                    />
                                                                )}
                                                            </div>
                                                        ) : col.type === "walletTabButtons" ? (
                                                            <div className="dashboard-wallettable-buttons">
                                                                <div className="button"
                                                                     onClick={() => openWalletPage("deposit")}
                                                                >
                                                                    {/*<Deposit/>*/}
                                                                    <p className="name">Deposit</p>
                                                                </div>
                                                                <div className="button"
                                                                    // data-tip="Withdraw"
                                                                    // data-for="withdraw"
                                                                    // data-iscapture="true"
                                                                     onClick={() => openWalletPage("withdraw")}
                                                                >
                                                                    {/*<Withdraw/>*/}
                                                                    <p className="name">Withdraw</p>
                                                                    {/*<ReactTooltip*/}
                                                                    {/*    className="customTooltip2"*/}
                                                                    {/*    id="withdraw"*/}
                                                                    {/*    type="dark"*/}
                                                                    {/*    place="top"*/}
                                                                    {/*    effect="solid"*/}
                                                                    {/*    multiline={true}*/}
                                                                    {/*/>*/}
                                                                </div>
                                                            </div>
                                                        ) : col.type === "chart" ?
                                                            <>{sparkLineCharts[index]?.length === 0 ? "━━━" : renderSparklineWithTooltip("5D", sparkLineCharts[index])}</>
                                                            : col.title === "Security" ? (
                                                                <>
                                                                    <div className="two_values">
                                                                        <p data-tip={d["bsSecurityMediumId"]}
                                                                           data-for="customTool"
                                                                           data-iscapture="true">
                                                                            {col.extract ?
                                                                                <div>
                                                                                    {`${d["tenor"]}-Day `}
                                                                                    {
                                                                                        d[col.key]?.split(" ")[0] === "GHGB"
                                                                                            ? "GH Govt. Bond"
                                                                                            : d[col.key]?.split(" ")[0] === "GHCB"
                                                                                                ? "GH Corp. Bond"
                                                                                                : d[col.key]?.split(" ")[0] === "GHLGB"
                                                                                                    ? "GH Municipal Bond"
                                                                                                    : d[col.key]?.split(" ")[0] === "GHTB"
                                                                                                        ? "GH Govt. bill"
                                                                                                        : d[col.key]?.split(" ")[0] === "GHLTB"
                                                                                                            ? "GH Municipal Bill"
                                                                                                            : d[col.key]?.split(" ")[0]
                                                                                    }
                                                                                </div>
                                                                                : d[col.key]}
                                                                        </p>
                                                                        <ReactTooltip
                                                                            className="customTooltip"
                                                                            id="customTool"
                                                                            type="dark"
                                                                            place="right"
                                                                            effect="solid"
                                                                            multiline={true}
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : col.key === "calculatedBidCleanPricePercentage" ? (
                                                                <>
                                                                    <div className="two_values">
                                                                        <p>{d[col.key] ? formatDataByType(d[col.key], col?.type, d?.securityCurrency?.symbol) : d[""] ? formatDataByType(d[" bidCleanPricePercentage"], col?.type, d?.securityCurrency?.symbol) : "━━━"}</p>
                                                                    </div>
                                                                </>
                                                            ) : col.key === "calculateAvailableCash" ? (
                                                                <>
                                                                    <div className="two_values">
                                                                        <p>{formatDataByType(parseFloat(d["cashBalance"]) - parseFloat(d["ledgerBalance"]), col?.type, d?.currency?.symbol)}</p>
                                                                    </div>
                                                                </>
                                                            ) : col.key === "calculatedAskCleanPricePercentage" ? (
                                                                <>
                                                                    <div className="two_values">
                                                                        <p>{d[col.key] ? formatDataByType(d[col.key], col?.type, d?.securityCurrency?.symbol) : d["askCleanPricePercentage"] ? formatDataByType(d["askCleanPricePercentage"], col?.type, d?.securityCurrency?.symbol || d?.currency?.symbol) : "━━━"}</p>
                                                                    </div>
                                                                </>
                                                            ) : col.title === "Price/Yield" ? (
                                                                d["orderSide"] === "BUY" ?
                                                                    (
                                                                        <div className="two_values">
                                                                            <p>{d?.assetClass === "BOND" ? d["calculatedBidCleanPricePercentage"] ? formatDataByType(d["calculatedBidCleanPricePercentage"], "4DPPrice", d?.securityCurrency?.symbol) : "━━━" : d["bidPrice"] ? formatDataByType(d["bidPrice"], "4DPPrice", d?.securityCurrency?.symbol) : "━━━"}/{d["bidYield"] ? formatDataByType(d["bidYield"], "percentage", d?.securityCurrency?.symbol || d?.currency?.symbol) : "━━━"}</p>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="two_values">
                                                                            <p>{d?.assetClass === "BOND" ? d["calculatedAskCleanPricePercentage"] ? formatDataByType(d["calculatedAskCleanPricePercentage"], "4DPPrice", d?.securityCurrency?.symbol || d?.currency?.symbol) : "━━━" : d["askPrice"] ? formatDataByType(d["askPrice"], "4DPPrice", d?.securityCurrency?.symbol || d?.currency?.symbol) : "━━━"} /{d["askYield"] ? formatDataByType(d["askYield"], "percentage", d?.securityCurrency?.symbol) : "━━━"}</p>
                                                                        </div>
                                                                    )
                                                            ) : col.title === "Issuer" ? (
                                                                <div className="two_values">
                                                                    <p>{d["issuer"] ? d["issuer"] : d["companyName"] ? d["companyName"] : "━━━"}</p>
                                                                </div>
                                                            ) : col.type === "securityCurrency" ? (
                                                                <div className="two_values">
                                                                    <p>{d[col.key]?.code}</p>
                                                                </div>
                                                            ) : col.title === "Type" ? d["orderSide"] === "BUY" ? "Bid" : "Ask"
                                                                : (
                                                                    <div className="two_values">
                                                                        <p
                                                                            className={formatDataForColor(d[col.key], col?.type)}>{d[col.key] ? formatDataByType(d[col.key], col?.type, d?.securityCurrency?.symbol || d?.currency?.symbol) : "━━━"}</p>
                                                                        {col?.subKeyParam && (
                                                                            <span
                                                                                className={formatDataForColor(d[col.key], col?.type)}>{`(${d[col?.subKey] ? numberWithCommasAndDecimal(d[col?.subKey]) : "━━━"}%)`}</span>
                                                                        )}
                                                                    </div>
                                                                )}
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                                {openRows[d[props?.idField]] && d?.children?.length > 1 && d?.children?.map((d: any, i: number) => {
                                    return (
                                        <div className="markettable__values" key={i}>
                                            {
                                                props?.columns?.map((col: any, i: number) => {
                                                    return (
                                                        <>
                                                            <div
                                                                className={`${col?.align === "left" ? "left" : "right"} ${(col.type === "chart" || col.type === "buttons" || col?.type === "price" || col?.type === "gain/loss" || col?.security) ? "markettable__values-childchart" : "markettable__values-childvalue"}`}
                                                                key={i}>
                                                                {col.type === "buttons" ? (
                                                                    <div>
                                                                        <Button btn_class="buy" text="BUY"
                                                                                setPopupProps={openPopUp}
                                                                                row={d}
                                                                        />
                                                                        <Button btn_class="sell" text="SELL"
                                                                                setPopupProps={openPopUp}
                                                                                row={d}
                                                                        />
                                                                    </div>
                                                                ) : col.type === "chart" ?
                                                                    <>{sparkLineCharts[index]?.length === 0 ? "━━━" : renderSparklineWithTooltip("5D", sparkLineCharts[i])}</>
                                                                    : col?.security ? ("") : (
                                                                        <div className="two_values">
                                                                            <p
                                                                                className={formatDataForColor(d[col.key], col?.type)}>{d[col.key] ? formatDataByType(d[col.key], col?.type, "") : "━━━"}</p>
                                                                            {col?.subKeyParam && (
                                                                                <span
                                                                                    className={formatDataForColor(d[col.key], col?.type)}>{`(${d[col?.subKey] ? numberWithCommasAndDecimal(d[col?.subKey]) : "━━━"}%)`}</span>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })}
                            </>
                        )
                    })}
                    {props?.data?.length === 0 && (
                        <p className="no_data">{props?.noDataText ? props?.noDataText : "No Data"}</p>
                    )}
                </div>
                <BuySellPopup
                    open={open}
                    theme={props.theme}
                    category={props.category}
                    onClose={handleClose}
                    currencySymbol={props?.currencySymbol}
                    onSubmit={handleSubmit}
                    popupType={popupType}
                    setPopupType={handlePopupType}
                    row={row}
                    clearFilter={clearFilterValue}
                    selectedDate={props?.selectedDate}
                />
                <OrderPreviewPopup
                    open={reviewPopup}
                    theme={props.theme}
                    onClose={handleClose2}
                    onEdit={handlePreviewEdit}
                    onSubmit={handleSubmit2}
                    popupType={popupType}
                    portfolioName={portfolioName}
                    payload={payload}
                    row={row}
                    productPrice={productPrice}
                />
                <ConfirmPopup
                    open={confirmPopup}
                    theme={props.theme}
                    onClose={handleClose3}
                    onSubmit={handleSubmit3}
                    popupType={popupType}
                    portfolio={portfolio}
                    payload={payload}
                />
                <SuccessPopup
                    open={successPopup}
                    theme={props.theme}
                    onClose={handleClose4}
                    popupType={popupType}
                />
            </div>

        </>
    )
}

export default MarketTable;
